import type { AvailablePaymentMethod, ShippingMethod } from '@robustastudio/e-commerce/common';

const fawryPaymentMethods = ['robusta_fawry_card', 'robusta_fawry_ewallet', 'robusta_fawry_pay_at_fawry'];

export const extendPlaceOrderPaymentMethods = [...fawryPaymentMethods];

export function extendUseApplicationPlaceOrder() {
  const extendState = ref<'idle' | 'loading' | 'error'>('idle');
  const { fawryPlaceOrder } = useFawryPlaceOrder();

  async function extendPlaceOrder(
    selectedPaymentMethod: Ref<AvailablePaymentMethod>,
    shippingMethod: Ref<ShippingMethod>,
  ) {
    if (fawryPaymentMethods.includes(selectedPaymentMethod.value?.code)) {
      try {
        extendState.value = 'loading';

        await fawryPlaceOrder(selectedPaymentMethod.value, shippingMethod.value);

        return;
      } catch (e) {
        extendState.value = 'error';
      }
    }
  }

  return {
    extendPlaceOrder,
    extendState,
  };
}

function useFawryPlaceOrder() {
  const { setShippingMethod } = useSetShippingMethod();
  const { setPaymentMethod } = usePaymentMethods();
  const { getFawryIframe, fawryIframe } = useFawryIframeOnCart();

  async function fawryPlaceOrder(paymentMethod: AvailablePaymentMethod, shippingMethod: ShippingMethod) {
    await setShippingMethod(shippingMethod);

    if (!paymentMethod) {
      throw new Error('No selected payment method');
    }

    await setPaymentMethod(paymentMethod.code);
    await getFawryIframe();
    if (!fawryIframe.value) throw new Error('cannot place order');

    if (fawryIframe.value) {
      window.location.replace(fawryIframe.value);
    }
  }

  return {
    fawryPlaceOrder,
  };
}

function useSetShippingMethod() {
  const { setShippingMethod: execute } = useShippingMethods();

  async function setShippingMethod(shippingMethod: ShippingMethod) {
    if (!shippingMethod) {
      throw new Error('No selected shipping method');
    }

    await execute({
      carrier_code: shippingMethod?.carrier_code,
      method_code: shippingMethod?.method_code as string,
    });
  }

  return {
    setShippingMethod,
  };
}
