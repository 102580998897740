<template>
  <div class="Checkout__container relative mt-8">
    <div slim data-cy-region="checkout-payment">
      <form @submit="onSubmit">
        <h3 class="flex items-center [ px-6 lg:px-0 ]">
          <SvgIconDeliveryInfo width="24" height="24" class="mr-2.5" />
          <span class="text-xl-1 font-semibold"> {{ $t('shippingInformation') }}</span>
        </h3>

        <dl class="[ grid grid-cols-1 ] [ font-bold ] [ mt-10 px-6 lg:px-0 ]">
          <div class="relative flex items-center justify-between">
            <div class="flex flex-col">
              <dt class="text-gray-90 font-normal">{{ $t('shippingMethod') }}</dt>
              <dd v-if="isStorePickup" class="mt-2">
                {{ $t('storePickup') }}
              </dd>
              <dd v-else class="mt-2">{{ $t('homeDelivery') }}</dd>
            </div>
          </div>

          <template v-if="address">
            <dt class="mt-7 text-gray-90 font-normal">{{ $t('shippingAddress') }}</dt>
            <dd class="mt-2 flex flex-col relative">
              <AppLink
                :to="`/checkout/${mode === 'guest' ? 'guest-shipping' : 'shipping'}`"
                class="flex items-center self-start [ absolute top-0 right-6 md:right-0 ]"
              >
                <svg-icon-edit class="w-6 h-6 mr-2.5" />
                <span> {{ $t('edit') }} </span>
              </AppLink>
              <span>{{ address.firstname }} {{ address.lastname }}</span>
              <span>{{ address.telephone }}</span>
              <span>{{ address.street[0] }}</span>
            </dd>
          </template>

          <!-- <template v-if="selectedShippingMethod">
          <dt class="mt-7 text-gray-900 font-normal">{{ $t('deliveryType') }}</dt>
          <dd class="mt-2">
            <div>
              {{ selectedShippingMethod.method_title }} , ( <Money :value="selectedShippingMethod.amount.value" /> )

              <span v-if="selectedShippingMethod.max_days && selectedShippingMethod.min_days">
                {{
                  $t('shippingMethods.deliverWithin', {
                    minDays: selectedShippingMethod.min_days,
                    maxDays: shippingMethod.max_days,
                  })
                }}
              </span>
            </div>
          </dd>
        </template> -->
        </dl>

        <div class="flex bg-primary-3-40 w-full h-1 mt-8 mb-10" />

        <h3 class="[ px-6 lg:px-0 ] [ text-xl-1 font-semibold ] [ flex items-center ]">
          <SvgIconWallet width="24" height="22" class="mr-4" />
          {{ $t('paymentInfo') }}
        </h3>

        <fieldset class="flex flex-col px-6 lg:px-0">
          <CheckoutCoupons class="mt-6" @is-open="(e: boolean) => (isCouponsOpen = e)" />
          <CheckoutLoyaltyPoints class="mt-10 px-6 lg:px-0" />
        </fieldset>

        <fieldset class="flex flex-col px-6 lg:px-0">
          <legend></legend>
          <CheckoutGiftCard
            class="mt-6"
            @is-open="(e: boolean) => (isGiftCardOpen = e)"
            @change-payment-methods="(e: typeof paymentMethods) => (paymentMethods = e)"
          />
        </fieldset>
        <fieldset v-if="isLoggedIn" class="flex flex-col px-6 lg:px-0">
          <CheckoutDsquaresLoyaltyPoints
            class="mt-5 px-0"
            @is-open="e => (isGiftCardOpen = e)"
            @change-payment-methods="e => (paymentMethods = e)"
          />
        </fieldset>

        <fieldset class="[ px-6 lg:px-0 mt-9 ] [ flex flex-col ]">
          <legend class="font-bold text-lg-1">{{ codeFree ? '' : $t('selectPaymentMethod') }}</legend>

          <div class="mt-7 relative">
            <div
              v-if="isUpdatingPaymentState"
              class="[ absolute top-0 left-0 ] flex items-center justify-center w-full h-full"
            >
              <Spinner class="w-8 h-8" />
            </div>

            <PaymentStrategyDirector
              :class="{ 'opacity-50 pointer-events-none': loading }"
              :payment-methods="paymentMethods"
              :code-free="codeFree"
            />
          </div>
        </fieldset>
        <!-- <CheckboxInput v-model="agreedToTerms" rules="required" class="px-5 md:px-0 mt-16">
        <template #default>
          <p class="text-sm text-black">
            By continuing you agree to our
            <a href="#" class="text-black underline">Terms and Conditions</a> and
            <a href="#" class="text-black underline">Privacy Policy</a>
          </p>
        </template>
      </CheckboxInput> -->

        <div class="hidden md:flex">
          <AppButton
            type="submit"
            class="mt-10 w-full md:w-80 justify-center uppercase"
            :loading="isPlacingOrder || isSubmitting || loading"
            inverted
          >
            {{ $t('placeOrder') }}
          </AppButton>
        </div>
      </form>
    </div>

    <div class="mt-16 md:mt-0 flex flex-col self-start md:sticky md:top-0 px-5 md:px-0">
      <div class="flex flex-col items-center justify-center">
        <CheckoutPaymentSummary class="w-full" :payment-fees="paymentFees">
          <template #cta-placeholder>
            <div class="md:hidden w-full mt-6 px-6">
              <AppButton
                type="button"
                class="flex md:hidden w-full justify-center uppercase"
                :loading="isPlacingOrder || isSubmitting || loading"
                inverted
                @click="onSubmit"
              >
                {{ $t('placeOrder') }}
              </AppButton>
            </div>
          </template>
        </CheckoutPaymentSummary>
      </div>

      <CheckoutCartSummary class="pb-16 lg:pb-24 -mt-16" />
    </div>
  </div>
</template>
<script setup lang="ts">
import type { AvailablePaymentMethod, ShippingMethod } from '@robustastudio/e-commerce/common';
import { useExtendPaymentFee } from '~/composables/cart';
const { extendPlaceOrder } = extendUseApplicationPlaceOrder();
const { addOrderSourceOnCart } = useSetOrderSourceOnCart();
const { address, isStorePickup } = useCartAttributes();
const { setPaymentMethodFees } = useExtendPaymentFee();
const { query } = useRoute();

const mode = computed(() => {
  return query.mode;
});

const isCouponsOpen = ref(false);
const isGiftCardOpen = ref(false);
const paymentFees = ref<{
  title: string;
  fees: number;
}>({
  title: '',
  fees: 0,
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { redirect } = useAppRouter();

definePageMeta({ layout: 'checkout' });
const { t: $t } = useI18n({ useScope: 'local' });

const { isLoggedIn } = useAuth();

/**
 *
 */
// watch(
//   isPageLoading,
//   newState => {
//     if (!newState && !itemCount.value) {
//       redirect('/');
//     }
//   },
//   {
//     immediate: true,
//   }
// );

const { validationSchema } = useCheckoutPaymentValidationSchema();
const { error } = useAlerts();
const {
  loading,
  submitCheckout,
  isPlacingOrder,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentMethods: cartPaymentMethods,
  shippingMethods: originalShippingMethods,
  isInvalidOptions,
  isUpdatingPaymentState,
  resetErrors,
} = useCheckout();
// type: Array as PropType<Array<Omit<AvailablePaymentMethod, 'is_deferred'> | null | undefined>>,
const paymentMethods = ref(cartPaymentMethods.value);
const { cartState } = useStoredCart();

onMounted(() => {
  if (!cartState.value.cartId) {
    redirect('/cart');
  }
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { handleSubmit, values, errors, isSubmitting, meta } = useForm({
  validationSchema,
  initialValues: {
    shippingMethod: null,
    paymentMethod: null,
  },
});

useSetAutoShippingMethod();
useSetAutoPaymentMethod();

useAppSeoMeta({
  title: 'Payment Information',
  description: 'Checkout by filling your payment information and paying for your order',
});

/**
 * Auto Select the shipping method if there is only one
 */
const { setValue: setFieldValue } = useField('shippingMethod');
const onSubmit = handleSubmit(
  async ({ paymentMethod, shippingMethod }) => {
    try {
      if (extendPlaceOrderPaymentMethods.includes((paymentMethod as unknown as AvailablePaymentMethod).code)) {
        await addOrderSourceOnCart();
        await extendPlaceOrder(
          ref(paymentMethod) as unknown as Ref<AvailablePaymentMethod>,
          ref(shippingMethod) as unknown as Ref<ShippingMethod>,
        );
        sessionStorage.removeItem('guest-address');
      } else {
        await submitCheckout(
          ref(paymentMethod) as unknown as Ref<AvailablePaymentMethod>,
          ref(shippingMethod) as unknown as Ref<ShippingMethod>,
        );
      }
      sessionStorage.removeItem('guest-address');
    } catch (e) {
      console.error(e);
    }
  },
  onError => {
    if (onError.errors.paymentMethod) {
      error('error', onError?.errors?.paymentMethod);
    } else if (onError.errors.shippingMethod) {
      error('error', onError?.errors?.shippingMethod);
    }
  },
);

/**
 * update payment method when fetched and show them
 * check if it contains code free, set payment method to free
 * other wise set to cash on delivery
 */
const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');

const unWatch = watch(cartPaymentMethods, newValue => {
  paymentMethods.value = newValue;
  unWatch();
});

paymentMethods.value = cartPaymentMethods.value;

watch(
  paymentMethods,
  newValue => {
    if (newValue.length) {
      const free = newValue.find(e => e?.code === 'free');
      if (free) {
        selectedPaymentMethod.value = free as AvailablePaymentMethod;
      } else {
        selectedPaymentMethod.value = newValue.find(e => e?.code === 'robusta_accept_cc') as AvailablePaymentMethod;
      }
      nextTick();
    }
  },
  {
    immediate: true,
  },
);

watch(selectedPaymentMethod, newSelected => {
  if (newSelected) {
    const code = selectedPaymentMethod?.value?.code ?? '';
    const data = setPaymentMethodFees(code);
    data.then(
      res =>
        (paymentFees.value = {
          title: newSelected.title,
          fees: res?.cart?.payment_fee ?? 0,
        }),
    );
  }
});

const codeFree = computed(() => paymentMethods.value?.some(e => e?.code === 'free'));

const shippingMethods = computed(() => {
  if (isStorePickup.value) {
    return originalShippingMethods.value.filter(method => method.carrier_code === 'pickuplocation');
  }

  return originalShippingMethods.value.filter(method => method.carrier_code !== 'pickuplocation');
});

watch(values, newValues => {
  if (isInvalidOptions.value && Object.keys(newValues).length && Object.keys(newValues)?.every(value => !!value)) {
    resetErrors();
  }
});

const isSet = ref(false);
watch(
  shippingMethods,
  newShippingMethods => {
    if (isSet.value) return;
    if (newShippingMethods.length === 1) {
      setFieldValue(newShippingMethods[0]);
      isSet.value = true;
    }
  },
  {
    immediate: true,
  },
);
</script>

<style lang="postcss" scoped>
.Checkout__container {
  display: grid;
  grid-template-columns: 1fr;
}

@screen lg {
  .Checkout__container {
    grid-template-columns: 1fr 472px;
    column-gap: 60px;
  }
}
</style>

<i18n>
{
  "en":{
    "placeOrder": "Place Order",
    "backToShipping": "Back To Shipping",
    "storePickup": "Store Pickup",
    "shippingInformation": "Shipping Information",
    "homeDelivery": "Home Delivery",
    "edit": "Edit",
    "shippingMethod": "Shipping Method",
    "shippingAddress": "Shipping Address",
    "deliveryType": "Delivery Type",
    "selectDeliveryType": "Select Delivery Type",
    "selectPaymentMethod": "Select Payment Method",
    "phone": "Phone",
    "name": "Name",
    "address": "Address",
    "payment": "Payment",
    "paymentInfo": "Payment Information"
  },
  "ar": {
    "placeOrder": "بدء الطلب",
    "backToShipping": "العوده للتوصيل",
    "storePickup": "الاستلام من الفرع",
    "shippingInformation": "معلومات التوصيل",
    "homeDelivery": "التوصيل المنزلى",
    "edit": "تعديل",
    "shippingMethod": "طريقه التوصيل",
    "shippingAddress": "عنوان التوصيل",
    "deliveryType": "نوع التوصيل",
    "selectDeliveryType": "اختار طريقه التوصيل",
    "selectPaymentMethod": "اختار طريقه الدفع",
    "phone": "التلفون",
    "name": "الاسم",
    "address": "العنوان",
    "payment": "الدفع",
    "paymentInfo": "معلومات الدفع"
  }
}
</i18n>
